import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useStaticModule from '@hooks/useStaticModule'
import Stage from '@components/stage'
import { IntroCopy } from '@objects/copy'
import Container from '@objects/container'

function Fotowettbewerb({ data, pageContext }) {
  const StyledIntroCopy = styled(IntroCopy)`
    h2 {
      font-size: ${({ theme }) => theme.fontSize.xl};
      font-weight: ${({ theme }) => theme.fontWeight.normal};
      line-height: ${({ theme }) => theme.lineHeight['9']};
      margin-top: ${({ theme }) => theme.spacing['9']};
    }
  `

  const { getStaticFieldMedia, getStaticFieldValue } = useStaticModule(
    pageContext.modules
  )

  const stageProps = pageContext.modules
    ? {
        image: getStaticFieldMedia(
          'fotowettbewerb',
          'fotowettbewerb.stage.image'
        ),
        article: {
          headline: getStaticFieldValue(
            'fotowettbewerb',
            'fotowettbewerb.headline'
          ),
        },
      }
    : null

  const introCopy = getStaticFieldValue('fotowettbewerb', 'fotowettbewerb.copy')

  return (
    <>
      {stageProps && <Stage type="headlineimage" {...stageProps} fixed />}
      <Container role="region" aria-label="Fotowettbewerb">
        {introCopy && (
          <div className="lg:w-8/12 lg:mx-auto">
            <StyledIntroCopy
              parseGlossaryCopy
              div
              className="mb-10"
              html={introCopy}
            />
          </div>
        )}
      </Container>
    </>
  )
}

Fotowettbewerb.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Fotowettbewerb
